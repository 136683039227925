// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas {
    width: 80vw;
    height: calc(100vh - 105px);
}

.canvas100 {
    width: 100vw;
    height: calc(100vh - 105px);
}

.canvas100learn {
    width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/Babylon/BabylonCanvas/BabylonCanvas.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".canvas {\r\n    width: 80vw;\r\n    height: calc(100vh - 105px);\r\n}\r\n\r\n.canvas100 {\r\n    width: 100vw;\r\n    height: calc(100vh - 105px);\r\n}\r\n\r\n.canvas100learn {\r\n    width: 100vw;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
