// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.level-container {
    display: flex;
    flex-wrap: wrap;
    gap: 120px;
    justify-content: center;
    align-items: center;
    height: 100vh;
    box-sizing: border-box;
}

.level-button {
    display: flex;
    background: #d9d9d9;
    color: #33334d;
    border: none;
    text-align: center;
    padding: 32px;
    text-decoration: none;
    cursor: pointer;
    width: 250px;
    height: 250px;
    box-sizing: border-box;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 0;
}

.level-button:hover {
    background-color: #9290C3;
}

.digitSize {
    font-weight: 400;
    font-size: 200px;
    text-align: center;
    margin: 0;
}

.wordSize {
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    margin: 0;
    margin-top: -60px;
}

.diffImgShape {
    position: absolute;
    width: 100px;
    height: auto;
    right: 25px;
    top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Levels/Levels.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,UAAU;IACV,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,MAAM;AACV;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,SAAS;AACb","sourcesContent":[".level-container {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    gap: 120px;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.level-button {\r\n    display: flex;\r\n    background: #d9d9d9;\r\n    color: #33334d;\r\n    border: none;\r\n    text-align: center;\r\n    padding: 32px;\r\n    text-decoration: none;\r\n    cursor: pointer;\r\n    width: 250px;\r\n    height: 250px;\r\n    box-sizing: border-box;\r\n    border-radius: 6px;\r\n    justify-content: center;\r\n    align-items: center;\r\n    gap: 0;\r\n}\r\n\r\n.level-button:hover {\r\n    background-color: #9290C3;\r\n}\r\n\r\n.digitSize {\r\n    font-weight: 400;\r\n    font-size: 200px;\r\n    text-align: center;\r\n    margin: 0;\r\n}\r\n\r\n.wordSize {\r\n    font-weight: 700;\r\n    font-size: 32px;\r\n    text-align: center;\r\n    margin: 0;\r\n    margin-top: -60px;\r\n}\r\n\r\n.diffImgShape {\r\n    position: absolute;\r\n    width: 100px;\r\n    height: auto;\r\n    right: 25px;\r\n    top: 25px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
