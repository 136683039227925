// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.constructionTree {
    /* height: 500px; Высота области дерева построений */
    overflow-y: auto;
    /* Добавляет вертикальный скролл, если содержимое не помещается */
    display: flex;
    flex-direction: column;
    /* Выстраивает элементы в столбик */
    gap: 5px;
    /* Расстояние между элементами */
    padding: 5px;
    /* Отступ от краев области */
}

.shape-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.shape-image {
    width: 40px;
    height: 40px;
}

.button-active {
    background-color: #4caf50;
    color: white;
    border: 1px solid #4caf50;
}`, "",{"version":3,"sources":["webpack://./src/Pages/WorkbenchPage/ConstructionTree.css"],"names":[],"mappings":"AAAA;IACI,oDAAoD;IACpD,gBAAgB;IAChB,iEAAiE;IACjE,aAAa;IACb,sBAAsB;IACtB,mCAAmC;IACnC,QAAQ;IACR,gCAAgC;IAChC,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".constructionTree {\r\n    /* height: 500px; Высота области дерева построений */\r\n    overflow-y: auto;\r\n    /* Добавляет вертикальный скролл, если содержимое не помещается */\r\n    display: flex;\r\n    flex-direction: column;\r\n    /* Выстраивает элементы в столбик */\r\n    gap: 5px;\r\n    /* Расстояние между элементами */\r\n    padding: 5px;\r\n    /* Отступ от краев области */\r\n}\r\n\r\n.shape-item {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 5px;\r\n}\r\n\r\n.shape-image {\r\n    width: 40px;\r\n    height: 40px;\r\n}\r\n\r\n.button-active {\r\n    background-color: #4caf50;\r\n    color: white;\r\n    border: 1px solid #4caf50;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
