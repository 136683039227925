// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 45%;
    box-sizing: border-box;
    font-family: 'Rajdhani',  'Jura', sans-serif;
    font-weight: 500;
}

.divStyle {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.btnStyle {
    margin: 5px;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.imgStyle {
    width: 100%;
    height: auto;
    object-fit: 'cover';
}`, "",{"version":3,"sources":["webpack://./src/components/ShapesButtons/Shapes.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,UAAU;IACV,sBAAsB;IACtB,4CAA4C;IAC5C,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,UAAU;IACV,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".button-container {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: space-around;\r\n}\r\n\r\n.button {\r\n    margin: 5px;\r\n    padding: 10px 20px;\r\n    font-size: 16px;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    width: 45%;\r\n    box-sizing: border-box;\r\n    font-family: 'Rajdhani',  'Jura', sans-serif;\r\n    font-weight: 500;\r\n}\r\n\r\n.divStyle {\r\n    padding: 5px;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n}\r\n\r\n.btnStyle {\r\n    margin: 5px;\r\n    width: 40px;\r\n    height: 40px;\r\n    background-color: transparent;\r\n    border: none;\r\n    cursor: pointer;\r\n    padding: 0;\r\n    display: inline-flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.imgStyle {\r\n    width: 100%;\r\n    height: auto;\r\n    object-fit: 'cover';\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
