// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  margin: 0;
  padding: 0;
  height: 100%;

  text-align: center;

  background-color: gray;
  font-family: "Rajdhani", "Jura", sans-serif;
  font-weight: 500;
  font-style: normal;
} 

.errorDescriptionConainer {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.headErrorDescr {
  font-size: 30px;
}

.colorLight {
  color: white;
}

.containerLinkTg {
  background-color: #5C6497;
  border-radius: 10px;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }

}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,YAAY;;EAEZ,kBAAkB;;EAElB,sBAAsB;EACtB,2CAA2C;EAC3C,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;;AAGA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;;AAEF","sourcesContent":[".App {\r\n  margin: 0;\r\n  padding: 0;\r\n  height: 100%;\r\n\r\n  text-align: center;\r\n\r\n  background-color: gray;\r\n  font-family: \"Rajdhani\", \"Jura\", sans-serif;\r\n  font-weight: 500;\r\n  font-style: normal;\r\n} \r\n\r\n.errorDescriptionConainer {\r\n  width: 90%;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.headErrorDescr {\r\n  font-size: 30px;\r\n}\r\n\r\n.colorLight {\r\n  color: white;\r\n}\r\n\r\n.containerLinkTg {\r\n  background-color: #5C6497;\r\n  border-radius: 10px;\r\n  text-align: center;\r\n}\r\n\r\n.App-logo {\r\n  height: 40vmin;\r\n  pointer-events: none;\r\n}\r\n\r\n@media (prefers-reduced-motion: no-preference) {\r\n  .App-logo {\r\n    animation: App-logo-spin infinite 20s linear;\r\n  }\r\n}\r\n\r\n.App-header {\r\n  background-color: #282c34;\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-size: calc(10px + 2vmin);\r\n  color: white;\r\n}\r\n\r\n\r\n.App-link {\r\n  color: #61dafb;\r\n}\r\n\r\n@keyframes App-logo-spin {\r\n  from {\r\n    transform: rotate(0deg);\r\n  }\r\n\r\n  to {\r\n    transform: rotate(360deg);\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
